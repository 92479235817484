<template>
    <div class="page-customer-details">
        <app-loader v-if="loading" fixed-on="desktop"></app-loader>

        <div class="row">
            <div class="col-6 col-mob-12">
                <app-table-details
                    title="Personal information"

                    :rows="rows.customer"
                    :data="customer_details"

                    @edit="onEdit"
                />
            </div>
            <div class="col-6 col-mob-12">
                <app-customer-event-log :customer_uuid="uuid" @refresh-comments-list="refreshCommentsList" />
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <app-comments :customer_uuid="uuid" ref="comments" />
            </div>
        </div>

        <dialog-customer-editing
            v-if="dialogs.editing.show"
            v-model="dialogs.editing.show"

            :uuid="uuid"
            :field="dialogs.editing.field"
            :origin-value="dialogs.editing.value"

            :have-customer-details="haveCustomerDetails"
            :have-external-ref1="haveExternalRef1"

            @closes="stopEditing"
            @updated="onCustomerUpdated"
        ></dialog-customer-editing>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'
import appCustomerEventLog from '@/components/app-customer-event-log'
import appComments from '@/components/app-comments'

import dialogCustomerEditing from './dialogs/dialog-customer-editing'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appTableDetails,
        appCustomerEventLog,
        appComments,
        dialogCustomerEditing,
    },

    data() {
        return {
            rows: {
                customer: [
                    { key: 'FirstName',    title: 'First name',      editable: true },
                    { key: 'LastName',     title: 'Last name',       editable: true },
                    { key: 'MobileNumber', title: 'Contact number',  editable: true },
                    { key: 'Email',        title: 'E-mail',          editable: true },
                    { key: 'UUID',         title: 'Account ID'                      },
                    { key: 'ExternalRef1', title: 'External Ref# 1', editable: true },
                    { key: 'ExternalRef2', title: 'External Ref# 2', editable: true },
                    { key: 'Active',       title: 'Active',          editable: true },
                ],
            },

            customer: {},

            dialogs: {
                editing: {
                    show: false,
                    field: null,
                    value: null,
                },
            },

            editing: null,
            editing_user: {},

            loading: false,
            loadedUUID: '',
        }
    },

    created() {
        this.init()
    },

    updated() {
        if (!this.loading && this.uuid && this.uuid != this.loadedUUID) {
            this.loading = true
            console.log('reloading', this.uuid, this.loadedUUID )
            this.init()
        } 
    },

    methods: {
        init() {
            this.loadCustomerInfo()
        },

        loadCustomerInfo() {
            this.loading = true

            this.$store.dispatch('getCustomerByUUID', { uuid: this.uuid })
                .then(customer => {
                    this.loadedUUID = this.uuid
                    this.customer = customer

                    this.loading = false
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.$router.push({ name: 'customers', replace: true })
                })
        },

        onCustomerUpdated(customer) {
            this.customer = customer
        },

        onEdit(field) {
            this.dialogs.editing.show = true
            this.dialogs.editing.field = field
            this.dialogs.editing.value = this.customer[field]
        },

        stopEditing() {
            this.dialogs.editing = {
                show: false,
                field: null,
                value: null,
            }
        },

        refreshCommentsList() {
            this.$refs.comments.search()
        },
    },

    computed: {
        uuid() {
            return this.$route.params.uuid
        },

        haveCustomerDetails() {
            return Boolean(this.customer.FirstName)
                && Boolean(this.customer.LastName)
                && Boolean(this.customer.MobileNumber)
                && Boolean(this.customer.Email)
        },

        haveExternalRef1() {
            return Boolean(this.customer.ExternalRef1)
        },

        customer_details() {
            return {
                FirstName: this.customer.FirstName,
                LastName: this.customer.LastName,
                MobileNumber: this.customer.MobileNumber,
                Email: this.customer.Email,
                UUID: this.customer.UUID,
                ExternalRef1: this.customer.ExternalRef1,
                ExternalRef2: this.customer.ExternalRef2,
                Active: this.customer.Active ? 'True' : 'False',
            }
        },
    },
}
</script>

<style lang="scss">
.page-customer-details {
    .row {
        .col-6 {
            display: flex;

            :only-child {
                width: 100%;
            }
        }

        .col-6,
        .col-12 {
            margin-bottom: 30px;
        }

        &:last-child {
            background-color: transparent;

            .col-6,
            .col-12 {
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .page-customer-details {
        .row {
            .col-6,
            .col-12 {
                margin-bottom: 15px;
            }
        }
    }
}
</style>
<template>
    <app-dialog class="dialog-customer-editing"
        v-if="show"
        v-model="show"

        :errors="errors"
        :processing="processing"

        :max-width="maxWidth"

        @closes="closes"
    >
        <template #head>
            <h3 v-if="head">{{ head }}</h3>
        </template>

        <template #form>
            <app-input  v-if="field == 'FirstName'"    v-model="local_value" label="First name"      :required="true" type="text"  :error="!!errors.fields[ fields[field] ]" @change="showNextError(field)" :disabled="processing"                 v-focus></app-input>
            <app-input  v-if="field == 'LastName'"     v-model="local_value" label="Last name"       :required="true" type="text"  :error="!!errors.fields[ fields[field] ]" @change="showNextError(field)" :disabled="processing"                 v-focus></app-input>
            <app-input  v-if="field == 'MobileNumber'" v-model="local_value" label="Mobile number"   :required="true" type="tel"   :error="!!errors.fields[ fields[field] ]" @change="showNextError(field)" :disabled="processing"                 v-focus></app-input>
            <app-input  v-if="field == 'Email'"        v-model="local_value" label="Email"           :required="true" type="email" :error="!!errors.fields[ fields[field] ]" @change="showNextError(field)" :disabled="processing"                 v-focus></app-input>
            <app-input  v-if="field == 'ExternalRef1'" v-model="local_value" label="External Ref# 1" :required="true" type="text"  :error="!!errors.fields[ fields[field] ]" @change="showNextError(field)" :disabled="processing" :maxlength="80" v-focus></app-input>
            <app-input  v-if="field == 'ExternalRef2'" v-model="local_value" label="External Ref# 2" :required="true" type="text"  :error="!!errors.fields[ fields[field] ]" @change="showNextError(field)" :disabled="processing" :maxlength="80" v-focus></app-input>
            <app-switch v-if="field == 'Active'"       v-model="local_value">Active</app-switch>
        </template>

        <template #btns>
            <button class="btn btn-primary" @click.prevent="confirm" :disabled="processing">Confirm</button>
            <button class="btn btn-cancel" @click.prevent="close" :disabled="processing">Cancel</button>
        </template>
    </app-dialog>
</template>


<script>
import appDialog from '@/components/app-dialog'

import appInput from '@/components/app-input'
import appSwitch from '@/components/app-switch'

import UserDetailsValidator from '@/validators/user-details-validator'
import errMessage from '@/helpers/errMessage'

const EDITABLE_FIELDS = {
    FirstName:    'firstname',
    LastName:     'lastname',
    MobileNumber: 'phone',
    Email:        'email',
}

const EXTERNAL_FIELDS = {
    ExternalRef1: 'extref1',
}

export default {
    props: {
        value:       { required: true },

        uuid:        { required: true },
        field:       { required: true },
        originValue: { required: true },

        haveCustomerDetails: { required: true },
        haveExternalRef1: { required: true },

        maxWidth: { type: [ Number, String ], default: 610 },
    },

    components: {
        appDialog,

        appInput,
        appSwitch,
    },

    data() {
        return {
            local_value: null,

            errors: {
                show: false,
                message: "",
                fields: {},
            },

            fields: EDITABLE_FIELDS,
            external_fields: EXTERNAL_FIELDS,

            processing: false,
        }
    },

    created() {
        this.local_value = this.originValue
    },

    methods: {
        close() {
            this.show = false
        },

        closes(with_button) {
            this.$emit('closes', with_button)
        },

        confirm() {
            if (this.validation()) {
                this.processing = true

                const payload = {
                    uuid: this.uuid,
                    params: {
                        [this.field]: this.local_value,
                    },
                }

                this.$store.dispatch('updateCustomer', payload).then(customer => {
                    this.$emit('updated', customer)
                    this.close()
                }).catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.processing = false
                })
            }
        },

        validation() {
            this.errors = {
                show: false,
                message: "",
                fields: {}
            }

            // console.log('this.haveExternalRef1', this.haveExternalRef1)
            // console.log('this.haveCustomerDetails', this.haveCustomerDetails)

            // console.log('this.fields[ this.field ]', this.fields[ this.field ])
            // console.log('this.external_fields[ this.field ]', this.external_fields[ this.field ])

            if (this.field in this.fields) {
                if (this.haveExternalRef1) {
                    UserDetailsValidator.mixin({
                        [this.fields[ this.field ]]: {
                            required: false,
                        },
                    })
                }

                if (UserDetailsValidator.isRuleExists(this.fields[ this.field ])) {
                    if (UserDetailsValidator.isInvalid(this.fields[ this.field ], this.local_value)) {
                        this.errors.fields[ this.field ] = UserDetailsValidator.getError()
                    }
                }
            } else if (this.field in this.external_fields) {
                if (this.haveCustomerDetails) {
                    UserDetailsValidator.mixin({
                        [this.external_fields[ this.field ]]: {
                            required: false,
                        },
                    })
                }

                if (UserDetailsValidator.isRuleExists(this.external_fields[ this.field ])) {
                    if (UserDetailsValidator.isInvalid(this.external_fields[ this.field ], this.local_value)) {
                        this.errors.fields[ this.field ] = UserDetailsValidator.getError()
                    }
                }
            }

            return !this.showNextError()
        },

        showNextError(except) {
            if (except) {
                delete this.errors.fields[except]
            }

            const errors = Object.keys(this.errors.fields)

            if (errors.length) {
                this.errors.show = true
                this.errors.message = this.errors.fields[errors[0]]
            } else {
                this.errors.show = false
            }

            return this.errors.show
        },
    },

    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },

        head() {
            let head = null

            switch (this.field) {
                case 'FirstName':    { head = 'Change first name'           } break
                case 'LastName':     { head = 'Change last name'            } break
                case 'MobileNumber': { head = 'Change mobile number'        } break
                case 'Email':        { head = 'Change email'                } break
                case 'ExternalRef1': { head = 'Change External Reference 1' } break
                case 'ExternalRef2': { head = 'Change External Reference 2' } break
                case 'Active':       { head = 'Change active'               } break
            }

            return head
        },
    },

    watch: {
        field() {
            this.local_value = {...this.field}
        },
    },
}
</script>

<style lang="scss">
.app-dialog.dialog-customer-editing .app-dialog-container .app-dialog-body {
    position: relative;

    img {
        margin: 0;
    }
}
</style>
